import React, { Component } from 'react';
import '../../styles/footer.css'

class Footer extends Component {

    render() {
        return (
            <footer className='footer'>
                <hr className='footerHr' />
                <p>2021 What The Tech, LLC&ensp;|&ensp;All rights reserved.</p>
            </footer>
        )
    }
}

export default Footer;
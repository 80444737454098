import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet'
import { useParams, useHistory } from 'react-router'
import axios from 'axios'
import Pagination from "react-js-pagination";
import BlogBlock from './blogBlock';



const BlogContainer = (props) => {
    let { page } = useParams()
    let history = useHistory()

    const [blogPosts, setBlogPosts] = useState([])
    const [blogCount, setBlogCount] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        fetchBlogs(page)
        setActivePage(page)
    }, [])

    const fetchBlogs = (pageNumber) => {
        axios.get(`/blog/fetchBlogs/${pageNumber}`)
        .then(response => {
            if(response.data){
                if(response.data.blogs.length < 1) {
                    fetchBlogs(1)
                } else {
                    setBlogPosts(response.data.blogs)
                    setPageCount(response.data.pageCount)
                    setBlogCount(response.data.blogCount)
                }
            }
        })
        .catch(function (error){
            // console.log(error);
        })
    }


    let blogPostsElement = (blogPosts.length > 0) ?
        blogPosts.map((blogPost) => (
            <BlogBlock blogPost={blogPost} />
        ))
        :
        <></>

    let paginationClick = (e) => {
        fetchBlogs(e)
        history.push('/blog/'+e)
        setActivePage(e)
    }


    if(blogPosts.length > 0) {
        return (
            <Col>
            <Helmet>
                <title>What The Tech | Blog</title>
                <meta name="description" content={'What The Tech Blog. What The Tech will help you make an informed decision on your next tech purchase. Read helpful articles and take short quizzes to get personalized technology recommendations.'}   />
            </Helmet>
            <Row>
                <Col className="centeredText pageTitle">
                <h1>Articles</h1>
                </Col>
    
            </Row>
            <Row>
                {blogPostsElement}
            </Row>
            <Row>
                <Col className='centeredText' >
                    <Pagination
                        prevPageText='prev'
                        nextPageText='next'
                        firstPageText='first'
                        lastPageText='last'
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={blogCount}
                        onChange={paginationClick}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </Col>
            </Row>
            </Col>
        )
    } else {
        return (
            <Col>
                <Helmet>
                    <title>What The Tech | Blog</title>
                    <meta name="description" content={'What The Tech Blog. What The Tech will help you make an informed decision on your next tech purchase. Read helpful articles and take short quizzes to get personalized technology recommendations.'}   />
                </Helmet>
                <Row>
                    <Col className="centeredText pageTitle">
                    <h1>Articles</h1>
                    </Col>
                </Row>
            </Col>
        )
    }

    
}

export default BlogContainer;
import React, { useEffect } from 'react';
import PageTitle from './pageTitle';
import QuizGrid from './quizGrid';
import TopQuizzes from './topQuizzes';
import TopBlogs from './topBlogs';
import { Helmet } from 'react-helmet'
import { Container, Col, Row } from 'reactstrap';

const HomePage =()=> {

    useEffect(() => {
        window.scrollTo(0,0);
    })

        return (
            <Container fluid={true}>
                <Helmet>
                    <title>What The Tech | Home</title>
                    <meta name="description" content={"What The Tech will help you make an informed decision on your next tech purchase. Take short quizzes to get personalized technology recommendations."}   />
                </Helmet>
                <Row className='pageTitleRow '>
                    <PageTitle />
                </Row>
                <Row>
                    <Col>
                    <h2 className='centeredText headingStyles'>Popular Quizzes</h2>
                    </Col>
                </Row>
                <Row className='align-items-center' >
                    <TopQuizzes />
                </Row>
                <Row className='align-items-center topBlogsSpacing' >
                    <TopBlogs />
                </Row>
            </Container>
        )
}

export default HomePage;
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { formatDate } from '../../js/commonFunctions'
import '../../styles/blogPage.css';



const TopBlogBlock = ({blogPost}) => {


    return (
        <>
        <Col xl='6' md='12' className=" col-centered centeredText">
                <a href={`/blog/${blogPost.date_published}/${blogPost.title_slug}`} >
                    <div className="blogBlock">
                        <Row>
                            <Col sm='4' xs='12'>
                                <div className='imageContainer'>
                                    <span className='imgHelper' ></span>
                                    <img src={require(`../../images/blog/${blogPost.shortcut_image.name}`)} className='blogBlockImage mx-auto' alt={blogPost.shortcut_image.title} />
                                </div>
                            </Col>
                            <Col sm='8' xs='12' className='my-auto'>
                                <div className='textContainer'>
                                <Row>
                                    <Col>
                                    <h3 className='leftJustify' > {blogPost.title} </h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='leftJustify'>
                                    <p className='smallText blogDetails' >
                                    <span><b>Author: </b></span>
                                    <span> {blogPost.author} </span>
                                    <span>&nbsp; | &nbsp;</span>
                                    <span><b>Date: </b></span>
                                    <span> {blogPost.date_published} </span>
                                    </p>

                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col className='leftJustify'>
                                    <p className='subtitle'> {blogPost.subtitle} </p>
                                    </Col>
                                </Row> */}
                                </div>

                            
                            </Col>     
                        </Row>
                    </div>
                </a>
        </Col>
        </>
    )
}

export default TopBlogBlock;
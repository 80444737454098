import React from 'react';

const QuestionCount =({currentQuestion, questionCount})=> {

    return (
        <>
        <h3 className='questionCounter centeredText' ><b>{currentQuestion+1}</b> of <b>{questionCount}</b> </h3>
        </>
    )
}

export default QuestionCount;
import ReactGA from 'react-ga';

//If an object is not in the values array, add it, if not, remove the object based on the ID. ID is set based on the question index and the question option index separated by a hyphen i.e. "0-2"
export const addOrRemoveObjectFromValuesArray = (id, values, questionId, questionValues, setQuestionValues, questionFormat) => {
    let valueObject = { id: id, values: values, questionId: questionId}
    if(questionValues.find(valueObject => {return valueObject.id === id})) {
        if(questionFormat === "multipleChoiceOne") {
            return;
        } else if(questionFormat === "cost") {
            let index = questionValues.findIndex(valueObject => valueObject.id === id)
            let tempQuestionValues = [...questionValues];
            tempQuestionValues[index] = valueObject
            setQuestionValues(tempQuestionValues)
            return;
        }
        let index = questionValues.findIndex(valueObject => valueObject.id === id)
        let tempQuestionValues = [...questionValues];
        tempQuestionValues.splice(index,1)
        setQuestionValues(tempQuestionValues)

        //Google Analytics
        let gaArrayString = ''
        values.map((value) => {
            gaArrayString = gaArrayString+' '+value.category +' '+value.value
        })

        ReactGA.event({
            category: 'Question Option',
            action: 'Unchecked '+id+' '+gaArrayString,
            label: questionFormat
        });
    } else {
        let tempQuestionValues = questionValues;
        if(questionFormat === "multipleChoiceOne") {
            //find questionValue where the question ID matches this question Id and delete them all
            tempQuestionValues = questionValues.filter(valueObject => {
                return valueObject.questionId !== questionId;
            })
        }
        setQuestionValues([...tempQuestionValues, valueObject])

        //Google Analytics
        let gaArrayString = ''
        values.map((value) => {
            gaArrayString = gaArrayString+' '+value.category +' '+value.value
        })

        ReactGA.event({
            category: 'Question Option',
            action: id+' '+gaArrayString,
            label: questionFormat
        });
    }
}


export function splitCamelCaseString(string) {
    let words = string.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(joinStrings).join(" ")
}

function joinStrings(word) {
    return word.charAt(0).toLowerCase() + word.substring(1);
}

export function capitalizeFirstLetter(string) {
    var words = string.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(capitalize).join(" ");
}

function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
}

export const removeLastLetter = (string) => {
    return string.slice(0, -1);
}

//Create a random string of 6 characters 
export const createSessionId = () => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 6; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const sortArrayBasedOnOtherArray = (arrayToSort, referenceArray) => {
    return arrayToSort.sort(function(a, b) {
        return referenceArray.indexOf(a) - referenceArray.indexOf(b);
      });
}

//Add commas to long numbers
export const addCommas = (number) => {
    var num_parts = number.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
}

//sort array of objects based on specific object property by passing this value to an array.sort() function
export const sortArrayOfObjects = (arrayToSort, sortProperty) => {

    const compare = (a, b) => {
        const value1 = a[sortProperty];
        const value2 = b[sortProperty];
    
        let comparison = 0;
        if(value1 > value2) {
            comparison = 1;
        } else if (value1 < value2) {
            comparison = -1
        }
        return comparison;
    }
    
    return arrayToSort.sort(compare);
}


//Format Date mm-dd-yyyy
export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [month, day, year].join('-');
}

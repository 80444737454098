import {capitalizeFirstLetter, sortArrayBasedOnOtherArray, addCommas} from './commonFunctions';

export const resultObjectToArray = (queryString, quizTitle) => {
    const queryObject = JSON.parse(queryString)
    let returnedArray = []

    if (queryObject.integrated_graphics && queryObject.gaming_graphics) {
        delete queryObject.integrated_graphics;
    }

    resultParserOptions.map((option) => {
        if (queryObject[option] !== undefined) {
            let optionFunctionReturn = functionsObject[option](queryObject[option], quizTitle)
            if(Array.isArray(optionFunctionReturn)){
                optionFunctionReturn.map((text) => {
                    returnedArray.push(text)
                })
            }
            else if(optionFunctionReturn){returnedArray.push(optionFunctionReturn)}
        } 
    })
    return returnedArray;
}

const weight = (property) => {
    if(property.$lte <= 4) {
        return {
            text: "Lightweight"
        }
    }
    return
}

const storage = (property, quizTitle) => {
    let string = ""
    if(quizTitle === "laptops"){    
        if(property.$gte >= 64 && property.$gte < 256){
            string = "Basic Storage or Greater"
        } else if (property.$gte >= 256 && property.$gte < 512)    {
            string = "Moderate Storage or Greater"
        } else {
            string = "Significant Storage or Greater"
        }
    } else {
        if(property.$gte >= 16 && property.$gte < 64){
            string = "Basic Storage or Greater"
        } else if (property.$gte >= 64 && property.$gte < 128)    {
            string = "Moderate Storage or Greater"
        } else {
            string = "Significant Storage or Greater"
        }
    }
    return {text: string}
}

const display_size = (property) => {
    return {
        text: `Screen Between ${property.$gte}" and ${property.$lte}"`
    }
    return
}

const display_resolution = (property) => {
    if(property.$gte >= 1440){
        return {
            text: "High Resolution Screen"
        }
    }    
    return
}

const cost = (property) => {
    return {
        text: `Price Between $${addCommas(property.$gte)} and $${addCommas(property.$lte)}`
    }
}

const battery = (property) => {
    if(property.$gte >= 8){
        return {text: "All Day Battery"}
    }
    return
}

const os = (property) => {
    let returnString = 'Running '
    property.$in.map((os, index) => {
        if (property.$in.length === 1) {
            returnString += osObject[os]
        }
        else {
            if((index+1) === property.$in.length) {
                returnString += " or "+osObject[os]
            } else if((index+2) === property.$in.length) {
                returnString += osObject[os]
            } else {
                returnString += osObject[os]+', '
            }
        }
    })
    return {text: returnString}
}

const touch = (property) => {
    return {text: "Touch Screen"}
}

const num_pad = (property) => { 
    return {text: "Number Pad"}
}

const ports = (property) => {
    return {text: "Includes "+property.$in[0].toUpperCase() +" Port"}
}

const integrated_graphics = (property) => {
    return [
        {text: "Basic Gaming"},
        {text: "Basic 3D Software Capable"}
    ]
}

const wireless_charging = (property) => {
    return {text: "Wireless Charging"}
}

const gaming_graphics = (property) => {
    return [
        {text: "Advanced Gaming"},
        {text: "Advanced 3D Software Capable"}
    ]
}

const professional_graphics = (property) => {
    return {text: "Advanced 3D Software Capable"}
}

const phone_size = (property) => {
    let sortedArray = sortArrayBasedOnOtherArray(property.$in, ["small", "medium", "large"])
    let returnString = "Sized "
    sortedArray.map((size, index) => {
        if (property.$in.length === 1) {
            returnString = "Sized "+capitalizeFirstLetter(size)
        }
        else {
            if((index+1) === property.$in.length) {
                returnString += " or "+capitalizeFirstLetter(size)
            } else if((index+2) === property.$in.length) {
                returnString += capitalizeFirstLetter(size)
            } else {
                returnString += capitalizeFirstLetter(size)+', '
            }
        }
    })
    
    return {text: returnString}
}

const water_resistant = (property) => {
    return {text: "Water Resistant"}
}

const stylus = (property) => {
    return {text: "Included Stylus"}
}

const backlit = (property) => {
    return {text: "Backlit Keyboard"}
}

const digitizer = (property) => {
    return {text: "Active Digitizer"}
}

const assistant = (property) => {
    let returnString = 'Supports '
    property.$in.map((assistant, index) => {
        if (property.$in.length === 1) {
            returnString += assistantObject[assistant]
        }
        else {
            if((index+1) === property.$in.length) {
                returnString += " and "+assistantObject[assistant]
            } else if((index+2) === property.$in.length) {
                returnString += assistantObject[assistant]
            } else {
                returnString += assistantObject[assistant]+', '
            }
        }
    })
    return {text: returnString}
}

const size = (property) => {
    let sortedArray = sortArrayBasedOnOtherArray(property.$in, ["small", "medium", "large"])
    let returnString = "Sized "
    sortedArray.map((size, index) => {
        if (property.$in.length === 1) {
            returnString = "Sized "+capitalizeFirstLetter(size)
        }
        else {
            if((index+1) === property.$in.length) {
                returnString += " or "+capitalizeFirstLetter(size)
            } else if((index+2) === property.$in.length) {
                returnString += capitalizeFirstLetter(size)
            } else {
                returnString += capitalizeFirstLetter(size)+', '
            }
        }
    })
    
    return {text: returnString}
}

const builtInLCD = (property) => {
    return {text: "Built-in LCD"}
}

const hqAudio = (property) => {
    return {text: "High Quality Audio"}
}

const colorOptions = (property) => {
    return {text: "Multiple Color Options"}
}

const portable = (property) => {
    return {text: "Portable"}
}

const streamingService = (property) => {
    let returnString = 'Supports '
    property.$in.map((streamingService, index) => {
        if (property.$in.length === 1) {
            returnString += streamingServiceObject[streamingService]
        }
        else {
            if((index+1) === property.$in.length) {
                returnString += " and "+streamingServiceObject[streamingService]
            } else if((index+2) === property.$in.length) {
                returnString += streamingServiceObject[streamingService]
            } else {
                returnString += streamingServiceObject[streamingService]+', '
            }
        }
    })
    return {text: returnString}
}



const resultParserOptions = [
    "weight",
    "storage",
    "display_size",
    "display_resolution",
    "touch",
    "num_pad",
    "cost",
    "battery",
    "ports",
    "os",
    "integrated_graphics",
    "wireless_charging",
    "gaming_graphics",
    "professional_graphics",
    "phone_size",
    "water_resistant",
    "stylus",
    "backlit",
    "digitizer",
    "assistant",
    "size",
    "builtInLCD",
    "hqAudio",
    "colorOptions",
    "portable",
    "streamingService" 
]

const functionsObject = {
    weight: weight,
    storage: storage,
    display_size: display_size,
    display_resolution: display_resolution,
    touch: touch,
    num_pad: num_pad,
    cost: cost,
    battery: battery,
    ports: ports,
    os: os,
    integrated_graphics: integrated_graphics,
    wireless_charging: wireless_charging,
    gaming_graphics: gaming_graphics,
    professional_graphics: professional_graphics,
    phone_size: phone_size,
    water_resistant: water_resistant,
    stylus: stylus,
    backlit: backlit,
    digitizer: digitizer,
    assistant: assistant,
    size: size,
    builtInLCD: builtInLCD,
    hqAudio: hqAudio,
    colorOptions: colorOptions,
    portable: portable,
    streamingService: streamingService
}

const osObject = {
    windows: "Windows",
    macos: "macOS",
    chromeos: "Chrome OS",
    ipados: "iPadOS",
    ios: "iOS",
    fireos: "Fire OS",
    android: "Android"
}

const streamingServiceObject = {
    appleMusic: "Apple Music",
    spotify: "Spotify",
    iHeartRadio: "iHeartRadio",
    pandora: "Pandora",
    amazonMusic: "Amazon Music",
    tidal: "Tidal",
    googlePlayMusic: "Google Play Music"
}

const assistantObject = {
    siri: "Siri",
    alexa: "Alexa",
    googleAssistant: "Google Assistant",
    cortana: "Cortana",
    bixby: "Bixby"
}
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import SideNav from './sideNav';
import QuizQuestion from './quizQuestion';
import PreviousButton from './previousButton';
import NextButton from './nextButton';
import SubmitButton from './submitButton';
import QuestionCount from './questionCount';
import NoMatch from '../noMatchPage/noMatch';
import ResultsLoading from '../results/resultsLoading';
import axios from 'axios';
import { capitalizeFirstLetter, removeLastLetter } from '../../js/commonFunctions';
import '../../styles/quiz/quiz.css';



const Quiz = ({quiz_title}) => {
    //quiz object from database
    const [quiz, setQuiz] = useState('');
    const [defaultPriceRangeBottom, setDefaultPriceRangeBottom] = useState('');
    const [defaultPriceRangeTop, setDefaultPriceRangeTop] = useState('');
    const [priceRangeTop, setPriceRangeTop] = useState('');
    const [loadingResults, setLoadingResults] = useState(false);
    const [noMatch404, setNoMatch404] = useState(false)
    const [paramsObject, setParamsObject] = useState({});
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [questionCount, setQuestionCount] = useState('')
    const [questionAnswers, setQuestionAnswers] = useState([])
    //set values based on index
    const [questionValues, setQuestionValues] = useState([])


    useEffect(() => {
        axios.get(`/quizzes/findQuiz/${quiz_title}`)
        .then(response => {
            if(response.data){
                setQuiz(response.data)
                setQuestionCount(response.data.questions.length)
                setDefaultPriceRangeBottom(response.data.default_price_range_bottom)
                setDefaultPriceRangeTop(response.data.default_price_range_top)
                setPriceRangeTop(response.data.price_range_top)
            } else {
                setNoMatch404(true)
            }
        })
        .catch(function (error){
            // console.log(error);
        })
    },[]) 

    let renderedContent;
    if(loadingResults) {
        renderedContent = 
        <ResultsLoading
            quizTitle={quiz_title} 
            paramsObject={paramsObject} 
        />
    } else if (quiz) {
        renderedContent = 
        <>
                <Row className='align-items-center vertical-center justify-content-center' >
                    <Col>
                        <h1 className='centeredText quizName' >{capitalizeFirstLetter(removeLastLetter(quiz_title))} Finder Quiz</h1>
                        <QuestionCount currentQuestion={currentQuestion} questionCount={questionCount}/>
                    </Col>
                </Row>
                <Row className='align-items-center vertical-center justify-content-center' >
                    <Col className="d-none d-lg-block">
                        <SideNav questions={quiz.questions} currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} questionValues={questionValues} />
                    </Col>
                    <Col md='8' xs='12'>
                        <Row className='justify-content-center' >
                          <QuizQuestion 
                            questions={quiz.questions} 
                            currentQuestion={currentQuestion} 
                            setCurrentQuestion={setCurrentQuestion}
                            questionAnswers={questionAnswers} 
                            setQuestionAnswers={setQuestionAnswers}
                            questionValues={questionValues}
                            setQuestionValues={setQuestionValues}
                            defaultPriceRangeBottom={defaultPriceRangeBottom}
                            defaultPriceRangeTop={defaultPriceRangeTop}
                            priceRangeTop={priceRangeTop}
                          />
                        </Row>
                        <Row>
                            <Col sm='12' className="movementButtons" >
                                <PreviousButton setCurrentQuestion={setCurrentQuestion} currentQuestion={currentQuestion} questionCount={questionCount} />
                                <NextButton setCurrentQuestion={setCurrentQuestion} currentQuestion={currentQuestion} questionCount={questionCount} questionValues={questionValues} />
                                {(currentQuestion === (questionCount-1)) ? 
                                    (<div>
                                        <SubmitButton 
                                            questionValues={questionValues}
                                            quizTitle={quiz_title}
                                            questionCount={questionCount}
                                            setParamsObject={setParamsObject}
                                            setLoadingResults={setLoadingResults}
                                        />
                                    </div>)
                                    : 
                                    (<span/>)
                                }
                            </Col>
  
                        </Row>
                    </Col>
                    <Col className="d-none d-lg-block">
                    </Col>
                </Row>
                </>
    } else if(noMatch404) {
        renderedContent = 
        <NoMatch />
    } else {
        renderedContent = 
        <></>
    }

    return (

        <Container 
            fluid={true}
        >
            {renderedContent}
        </Container>
    )
}

export default Quiz;

import React, {useState, useEffect} from 'react';
import { formatDate } from '../../js/commonFunctions'

const ResultPriceDisclaimer = ({resultDate}) => {
    let startingDate = new Date("2021/03/07")

    let dateText = (new Date(resultDate) > startingDate)?
        (
            <span className='priceDisclaimer' >Prices effective as of {formatDate(resultDate)}.</span>
        )
        :
        (
            <></>
        )

    return (
    <>
        {dateText}
        <p className='priceDisclaimer' >Prices shown are subject to change on Amazon.</p>
    </>
    )
}

export default ResultPriceDisclaimer;
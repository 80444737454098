import React, { useState } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Col } from 'reactstrap';
import '../../styles/header.css';

const Header = (props) => {

    const [isOpen, setIsOpen] = useState(false)

      const toggle = () => {
          setIsOpen(!isOpen)
      }

      const toggleOff = () => {
          if (isOpen == true) {
              toggle()
          }
      }

        return (
            <Col className='headerCol'>
                <Navbar color="light" light expand="md" className='headerNavbar'>
                    <RouterNavLink className='headerBrand' to='/'>
                        <img src={require('../../images/logo.svg')} className='headerLogo' alt='logo'/> 
                        WhatTheTech
                    </RouterNavLink>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                        <NavItem>
                            <RouterNavLink to='/' onClick={toggleOff} className="navItem">Home</RouterNavLink>
                        </NavItem>
                        <NavItem>
                            <RouterNavLink to='/quizzes' onClick={toggleOff} className="navItem">Quizzes</RouterNavLink>
                        </NavItem>
                        <NavItem>
                            <RouterNavLink to='/blog' onClick={toggleOff} className="navItem">Blog</RouterNavLink>
                        </NavItem>
                        <NavItem>
                            <RouterNavLink to='/videos' onClick={toggleOff} className="navItem">Videos</RouterNavLink>
                        </NavItem>
                        <NavItem>
                            <RouterNavLink to='/about' onClick={toggleOff} className="navItem" >About</RouterNavLink>
                        </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Col>
        )
}

export default Header;
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import QuestionOption from './questionOption';
import QuestionOptionCost from './questionOptionCost';
import '../../styles/quiz/quiz.css';

const QuizQuestion = ({questions, currentQuestion, setCurrentQuestion, questionAnswers, setQuestionAnswers, questionValues, setQuestionValues, defaultPriceRangeBottom, defaultPriceRangeTop, priceRangeTop}) => {
    const [questionText, setQuestionText] = useState('')
    const [questionOptions, setQuestionOptions] = useState([]);
    const [radioSelected, setRadioSelected] = useState([])
    const [checkboxSelected, setCheckboxSelected] = useState([])
    const [costSelected, setCostSelected] = useState([])

    useEffect(() => {
        if(questions){
            setQuestionText(questions[currentQuestion].question_text)
            setQuestionOptions(questions[currentQuestion].question_options)
        }
    }, [questions, currentQuestion])


    let questionSubtext = '';

    if(questions[currentQuestion].question_format === "multipleChoiceOne") {
        questionSubtext = 'Select One Option';     
    } else if (questions[currentQuestion].question_format === "multipleChoiceAll") {
        questionSubtext = 'Select All That Apply';
    } else if (questions[currentQuestion].question_format === "cost") {
        questionSubtext = 'Adjust The Price Slider To Set Price'
    }


    let questionOptionsElement = (questions[currentQuestion].question_format === "cost") 
        ? 
        (<QuestionOptionCost 
            questionFormat={questions[currentQuestion].question_format}
            costSelected={costSelected}
            setCostSelected={setCostSelected}
            currentQuestion={currentQuestion}
            questionValues={questionValues}
            setQuestionValues={setQuestionValues}
            questionAnswers={questionAnswers}
            setQuestionAnswers={setQuestionAnswers}
            defaultPriceRangeBottom={defaultPriceRangeBottom}
            defaultPriceRangeTop={defaultPriceRangeTop}
            priceRangeTop={priceRangeTop}
        />)
        :  
        (
        questionOptions.map((option, index) => 
            <QuestionOption 
                key={index}
                questionFormat={questions[currentQuestion].question_format}
                currentQuestion={currentQuestion}
                optionDetails={option}
                radioSelected={radioSelected}
                setRadioSelected={setRadioSelected}
                checkboxSelected={checkboxSelected}
                setCheckboxSelected={setCheckboxSelected}
                questionValues={questionValues}
                setQuestionValues={setQuestionValues}
                questionAnswers={questionAnswers}
                setQuestionAnswers={setQuestionAnswers}
                optionIndex={index}
            />
        )
    ); 

    return (
        <Col >
            <Row className='justify-content-center' id='quizQuestionTextRow' >
                <Col>
                <span className="questionText">{questionText}</span>
                <span className="questionSubtext">{questionSubtext}</span>
                </Col>
            </Row>
            <Row>
                {questionOptionsElement}
            </Row>
            
        </Col>
    )
}

export default QuizQuestion;
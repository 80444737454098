import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { capitalizeFirstLetter, removeLastLetter } from '../../js/commonFunctions';
import '../../styles/quizIntro.css';
import LaptopIntro from '../quizIntros/laptops';
import PhoneIntro from '../quizIntros/phones';
import TabletIntro from '../quizIntros/tablets';
import SmartSpeakerIntro from '../quizIntros/smartSpeakers';

const quizIntros = {
    laptops: <LaptopIntro />,
    phones: <PhoneIntro />,
    tablets: <TabletIntro />,
    smartSpeakers: <SmartSpeakerIntro />

}


const QuizIntro = ({quiz_title, setQuizStarted}) => {

    let introComponent = quizIntros[quiz_title]

    useEffect(() => {
        window.scrollTo(0,0);
    })

    let startQuiz = () => {
        setQuizStarted(true)
    }


    return (
        <Container fluid={true}>
            <Row>
                <Col className='centered introHeader' >
                <h1>
                    {capitalizeFirstLetter(removeLastLetter(quiz_title))} Finder Quiz
                </h1>
                </Col>
            </Row>
            <Row >
                <Col className='centered' >
                    <Button size='lg' className='startQuizButton' onClick={startQuiz} >
                        Start Quiz
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
                <Col className='centered introText' md="10" lg="8">
                    {introComponent}
                </Col>
                <Col>
                </Col>
            </Row>

        </Container>
    )
}

export default QuizIntro;
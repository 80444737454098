import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { capitalizeFirstLetter, removeLastLetter } from '../../js/commonFunctions';

const QuizBlock = ({quizTitle, quizImage, altQuizTitle}) => {

    const quizSelected = (quizName) => {
        ReactGA.event({
            category: 'Quiz Buttons',
            action: quizName+' quiz selected',
            label: 'All Quizzes Button'
        });
    }

    let quizTitleText = (!altQuizTitle) ?
    (
        <div className='quizBlockText'>{quizTitle}</div>
    ) 
    :
    (
        <div className='quizBlockText'>{altQuizTitle}</div>
    )

    return (
        <Link to={`/quizzes/${quizTitle}`} className='col-lg-4 col-md-6 col-sm-6' onClick={()=> quizSelected(quizTitle)}>
            <div className='quizBlock'>
                <img src={require(`../../images/homePage/${quizImage}.svg`)} className='quizBlockImage mx-auto' alt={`${capitalizeFirstLetter(removeLastLetter(quizTitle))} finder quiz`}/>
                <div className='quizBlockTextContainer'>
                    {quizTitleText}
                </div>
            </div>
        </Link>

    )
}

export default QuizBlock;
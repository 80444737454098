//King function to create the results query
export const createResultsQuery = (resultsArray) => {
    let query = {};
    resultsArray.map((valueObject) => {
        const dynamicFunctionCall = valueObject.category+"Query"
        query[valueObject.category] = functionsObject[dynamicFunctionCall](valueObject.category, valueObject.value, query, resultsArray)
    })
    return query;
}

//find if an object property already exists in an object
const findDuplicateObjectProperty = (object, property) => {
    if(object[property] === undefined) {
        return false
    } else {
        return true
    }
}

//Returns a query that looks for values in an array
const createFindInQuery = (resultsArray, property) => {
    let inQueryArray = [];
    resultsArray.map((valueObject) => {
        if(valueObject.category === property) {
            inQueryArray.push(valueObject.value)
        }
    })
    return {$in: inQueryArray}
}

//Returns a range finder query with the high end and low end values of an array
const createFindRangeQuery = (resultsArray, property, equalToType) => {
    let rangeQueryArray = [];

    resultsArray.map((valueObject) => {
        if(valueObject.category === property) {
            rangeQueryArray.push(parseInt(valueObject.value))
        }
    })

    let sortedArray = rangeQueryArray.slice().sort((a,b) => {
        return a-b
    })

    if(equalToType === 'lte') {
        return {$lte: sortedArray[sortedArray.length-1]}
    } else if(equalToType === 'gte') {
        return {$gte: sortedArray[0]}
    } else {
        return {$gte: sortedArray[0], $lte: sortedArray[sortedArray.length-1]}
    }

}

//default query to pass to all the other ones
const defaultQueryFunction = (property, value, query, resultsArray, queryType, equalToType) => {
    if(findDuplicateObjectProperty(query, property)) {
        if(queryType === 'range') {
            return createFindRangeQuery(resultsArray, property, equalToType);
        } else if(queryType === 'rangeSlider') {
            return {$gte: value[0], $lte: value[1]}
        } else {
            return createFindInQuery(resultsArray, property)
        }
    } else {
        if(equalToType === 'gte'){
            return {$gte: parseInt(value)};
        } else if (equalToType === 'lte') {
            return {$lte: parseInt(value)};
        } else if(equalToType === 'gtelte') {
            return {$gte: value[0], $lte: value[1]}
        } else {
            return createFindInQuery(resultsArray, property);
        }      
    }
}


//
//Value Queries
//

const blankAnswerQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}
const weightQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray, 'range', 'lte')
}

const storageQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray, 'range', 'gte')
}

const ramQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray, 'range', 'gte')
}

const display_sizeQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray, 'range', 'gtelte')
}

const display_resolutionQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray, 'range', 'gte')    
}

const costQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray, 'rangeSlider', 'gtelte') 
}

const batteryQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray, 'range', 'gte')
}

const release_yearQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray, 'range', 'gte')    
}

const processor_coresQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray, 'range', 'gte')    
}

const osQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const touchQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const two_in_oneQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const brandQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const storage_typeQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const num_padQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const portsQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const processorQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const integrated_graphicsQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const wireless_chargingQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const tabletQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const gaming_graphicsQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const professional_graphicsQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const phone_sizeQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const water_resistantQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const stylusQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const backlitQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const digitizerQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const assistantQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const sizeQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const builtInLCDQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const hqAudioQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const colorOptionsQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const portableQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

const streamingServiceQuery = (property, value, query, resultsArray) => {
    return defaultQueryFunction(property, value, query, resultsArray)    
}

//All functions must also be stored here to dynamically call them based on strings
//this is the list of all possible value categories
const functionsObject = {
    blankAnswerQuery: blankAnswerQuery,
    weightQuery: weightQuery,
    ramQuery: ramQuery,
    storageQuery: storageQuery,
    display_sizeQuery: display_sizeQuery,
    display_resolutionQuery: display_resolutionQuery,
    touchQuery: touchQuery,
    two_in_oneQuery: two_in_oneQuery,
    brandQuery: brandQuery,
    storage_typeQuery: storage_typeQuery,
    num_padQuery: num_padQuery,
    costQuery: costQuery,
    batteryQuery: batteryQuery,
    portsQuery: portsQuery,
    osQuery: osQuery,
    processorQuery: processorQuery,
    integrated_graphicsQuery: integrated_graphicsQuery,
    release_yearQuery: release_yearQuery,
    wireless_chargingQuery: wireless_chargingQuery,
    tabletQuery: tabletQuery,
    processor_coresQuery: processor_coresQuery,
    gaming_graphicsQuery: gaming_graphicsQuery,
    professional_graphicsQuery: professional_graphicsQuery,
    phone_sizeQuery: phone_sizeQuery,
    water_resistantQuery: water_resistantQuery,
    stylusQuery: stylusQuery,
    backlitQuery: backlitQuery,
    digitizerQuery: digitizerQuery,
    assistantQuery: assistantQuery,
    sizeQuery: sizeQuery,
    builtInLCDQuery: builtInLCDQuery,
    hqAudioQuery: hqAudioQuery,
    colorOptionsQuery: colorOptionsQuery,
    portableQuery: portableQuery,
    streamingServiceQuery: streamingServiceQuery
}
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import YouTube from 'react-youtube';



const YoutubeVideoObject = ({videoData}) => {

    let video = (videoData && videoData.id.videoId) ?
        <div className="videoContainer">
            <iframe width="560" height="315" src={"https://www.youtube.com/embed/"+videoData.id.videoId} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        :
        <></>

    let videoHeading = (videoData && videoData.snippet.title) ?
        <h3 className='videoHeading'>{videoData.snippet.title}</h3>
        :
        <></>

    return (
        <Col className='centered videoObjectContainer' md="12" lg="6">
            {videoHeading}
            {video}
        </Col>
    )

}

export default YoutubeVideoObject;



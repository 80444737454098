import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';



const PhoneIntro = () => {

    useEffect(() => {
    })


    return (
        <>
        <h2>
            What Phone Should You Buy?
        </h2>
        <p>
        What The Tech's phone finder quiz uses your quiz answers to recommend a phone that matches your lifestyle and needs as closely as possible.
        </p>

        <p>
            <b className='nonHeaderHeading'>
                Here are some things to keep in mind when choosing your phone preferences:
            </b>
        </p>
        <ul className='leftJustify'>
            <li>
                What The Tech prioritizes your preferred price above all else. This means that we try to find you a phone that fits your budget even if it doesn't necessarily have every feature you select. 
            </li>
            <li>
                More expensive phones can perform tasks faster and tend to be compatible with new apps and features for a longer period of time. It is important to weigh your budget with how long you intend to keep your phone.
            </li>
            <li>
                Cost savings usually come at the expense of features such as a higher-resolution screen, advanced camera capabilities, and greater storage.
            </li>
            <li>
                All recommended phones have access to cloud storage to offload photos and videos to free up space on your device for new content. This means that you can access your media even on phones with little storage space.
            </li>
            <li>
                Currently no iPhones have stylus capabilities or USB-C ports.
            </li>
        </ul>
        </>
    )
}

export default PhoneIntro;
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import axios from 'axios'
import TopBlogBlock from './topBlogBlock';

const TopBlogs = (props) => {
    const [blogPosts, setBlogPosts] = useState([])

    useEffect(() => {
        fetchBlogs()
    }, [])

    const fetchBlogs = () => {
        axios.get(`/blog/fetchTopBlogs`)
        .then(response => {
            if(response.data){
                setBlogPosts(response.data)
            }
        })
        .catch(error => {
            // console.log(error);
        })
    }
    
    let blogPostsElement = (blogPosts.length > 0) ?
        blogPosts.map((blogPost, index) => (
            <TopBlogBlock key={index} blogPost={blogPost} />
        ))
        :
        <></>

    if(blogPosts.length > 0) {
        return (
            <Col>
                <Row>
                    <Col className="centeredText pageTitle topArticlesTitle">
                        <h1>Recent Articles</h1>
                    </Col>
                </Row>
                <Row>
                {blogPostsElement}
                </Row>
            </Col>
        )
    } else {
        return (
            <>
            </>
        )
    }

}

export default TopBlogs;
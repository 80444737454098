import React, { useEffect } from 'react';
import QuizGrid from '../homePage/quizGrid'
import { Helmet } from 'react-helmet'
import { Col } from 'reactstrap';


const Quizzes = (props) => {

    useEffect(() => {
        window.scrollTo(0,0);
    })


    return (
        <>
        <Helmet>
            <title>What The Tech | Quizzes</title>
            <meta name="description" content={"Take short quizzes to get personalized technology recommendations for what tech best fits your lifestyle and budget."}   />
        </Helmet>
        <Col sm='12' className='centered'>
            <h1>Quizzes</h1>
        </Col>
        <Col className='centered'>
            <p className='generalText'>Take short quizzes to get personalized technology recommendations for what tech best fits your lifestyle and budget.</p>
        </Col>
        <Col sm='12' className='centered'>
            <h3 className='greySubtext' >Select a quiz below to get started</h3>
        </Col>
        <QuizGrid />
        </>
    )
}

export default Quizzes;
import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { Helmet } from 'react-helmet'
import {
    FacebookIcon,
    TwitterIcon,
    EmailIcon,
    InstagramIcon
  } from 'react-share';
import '../../styles/about.css';


const About = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    })

        return (
            <>
            <Helmet>
                <title>What The Tech | About</title>
                <meta name="description" content={"What The Tech will help you make an informed decision on your next tech purchase. Take short quizzes to get personalized technology recommendations."}   />
            </Helmet>
            <Col>
            </Col>
            <Col className='centered' md="10" lg="8">
                <h2 className='disclaimerHeader'>About Us</h2>
                <p className="generalText">Whether you are shopping for yourself or a loved one, What The Tech will help you make an informed decision on your next tech purchase. Through taking one of our quizzes, or reading one of our blogs posts, our goal is to help you find your perfect device based on what actually matters to you. No marketing mumbo-jumbo, no confusion. Just personalized recommendations &ndash; presented as clearly as possible.</p>
                <hr />
                <h2 className='disclaimerHeader'>Contact What The Tech</h2>
                <a target="_blank" rel="noopener noreferrer" href="mailto:contact@whatthetech.io" className='contactIcons' ><EmailIcon size={48} round={true} bgStyle={{fill: "#1C7FA6"}} /></a>
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/WhatTheTechIO" className='contactIcons' ><TwitterIcon size={48} round={true} /></a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/WhatTheTechIO" className='contactIcons' ><FacebookIcon size={48} round={true} /></a>
                <hr />
                <h2 className='disclaimerHeader'>Affiliate Disclaimer</h2>
                <p className="generalText">What The Tech is an Amazon Associate and earns revenue based on qualifying Amazon affiliate purchases.</p>
                <p className="generalText">
                Recommended products are not sponsored by Amazon, recommendations are selected purely based on answers to What The Tech quizzes.
                </p>
            </Col>
            <Col>
            </Col>
            </>
        )
}

export default About;
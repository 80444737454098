import React from 'react';

const ResultReason = ({reason}) => {

    return (
        <div>
            <span className="checkMark"></span>
            <span>{reason.text}</span>
        </div>
    )
}

export default ResultReason;
import React, { useEffect, useState }  from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router'
import Header from './components/header/header';
import Footer from './components/footer/footer';
import HomePage from './components/homePage/homePage';
import Quizzes from './components/quizzes/quizzes';
import QuizContainer from './components/quizPage/quizContainer';
import ResultsContainer from './components/results/resultsContainer';
import BlogContainer from './components/blog/blogContainer';
import BlogPost from './components/blogPost/blogPost';
import TopTech from './components/topTechPage/topTechPage';
import Reviews from './components/reviews/reviews';
import About from './components/about/about';
import Videos from './components/videosPage/videosPage';
import NoMatch from './components/noMatchPage/noMatch';
import Logo from './components/logo/logo';
import { Row, Container } from 'reactstrap';
import './styles/generalStyles.css'
import ReactGA from 'react-ga';

//Google Analytics
ReactGA.initialize('UA-158837235-1');

const App = (props) => {
  
  useEffect(() => {
    props.history.listen((location, action) => {
        ReactGA.pageview(location.pathname);
    })
  })

  return (
  <>
  <Container
    fluid={true}
    className="primaryContainer"
  >
  <div className="primaryContainerInterior">
  <Row>
    <Header />
  </Row>
  <Row>
    <Switch>
      <Route path='/' exact component={HomePage} />
      <Route path='/quizzes' exact component={Quizzes} />
      <Route path='/quizzes/:quiz_title' exact component={QuizContainer} />
      {/* Legacy Routes Start */}
      <Route path='/quiz/:quiz_title/results/:id' exact component={ResultsContainer} />
      {/* Legacy Routes End */}
      <Route path='/quizzes/:quiz_title/results/:id' exact component={ResultsContainer} />
      <Route path='/blog' exact component={props => <BlogContainer key={'/blog'} />} />
      <Route path='/blog/:page' exact component={BlogContainer} />
      <Route path='/blog/:date/:title' exact component={BlogPost} />
      <Route path='/videos' component={Videos} />    
      <Route path='/top-tech' component={TopTech} />    
      <Route path='/reviews' component={Reviews} />
      <Route path='/about' component={About} />
      <Route path='/logo' component={Logo} />
      <Route component={NoMatch} />
    </Switch>
  </Row>
  </div>

  </Container>
  <Footer />
  </>
  );
}

export default withRouter(App);
import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';



const LaptopIntro = () => {

    useEffect(() => {
    })


    return (
        <>
        <h2>
            What Laptop Should You Buy?
        </h2>
        <p>
            What The Tech's laptop finder quiz uses your quiz answers to recommend a laptop that matches your lifestyle and needs as closely as possible.
        </p>
        <p className='nonHeaderHeading'>
            <b>
            Here are some things to keep in mind when choosing your laptop preferences:
            </b>
        </p>
        <ul className='leftJustify'>
            <li>
                What The Tech prioritizes your preferred price above all else. This means that we try to find you a laptop that fits your budget even if it doesn't necessarily have every feature you select. 
            </li>
            <li>
                While there are many great budget laptops, the cost savings usually come at the expense of features such as a higher resolution-screen, advanced gaming capabilities, and premium build quality.
            </li>
            <li>
                Gaming laptops are heavier than the typical laptop, and they also tend to use up the battery more quickly due to the power-hungry task of running a graphics card.
            </li>
            <li>
                Current Apple computers do not have number pads, touch screens, or HDMI ports. However, there are many adapters that can be used for HDMI connections.
            </li>
            <li>
                Chromebooks typically have smaller storage capabilities as they are intended to be used primarily with cloud storage.
            </li>
        </ul>
        </>
        
    )
}

export default LaptopIntro;
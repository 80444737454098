import React, {useState, useEffect} from 'react';
import { Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const ResultsLoading =({quizTitle, paramsObject})=> {
    let history = useHistory();
    const [resultsId, setResultsId] = useState('')
    const [loadingDone, setLoadingDone] = useState(false)

    useEffect(() => {
        if(!loadingDone && !resultsId){
            startTimeout();
            findMatches();
        } else if (loadingDone && resultsId) {
            sendResultId()
        }
    })

    const findMatches = () => {
        let newQueryObject = paramsObject;
        newQueryObject.params["available"] = { '$in': ['true'] }
        if(newQueryObject.params["blankAnswer"]) {
            delete newQueryObject.params["blankAnswer"]
        }
        axios.get(`/devices/findMatches/${quizTitle}`, newQueryObject)
        .then(res => {
            if(res.data) {
                setResultsId(res.data)
            }
        })
    }

    const startTimeout = () => {
        setTimeout(() => {
            setLoadingDone(true);
        }, 2000);
    }

    const sendResultId = () => {
        history.push(`/quizzes/${quizTitle}/results/${resultsId}`)
    }

    let stillLoading = (loadingDone) ?
        (
            <>
            {/* <br />
            <b><span>Still Calculating Results...</span></b> */}
            </>
        )
        :
        (
            <></>
        )


    return (
        <>
        <Col className='col-centered centeredText' >
            <svg xmlns="http://www.w3.org/2000/svg" className='resultLoaderSvg' viewBox="0 0 682 682">
            <path id="gear" className="cls-1 icon-gear" d="M584.023,367.368l-45.993,8.11a198.585,198.585,0,0,1-9.619,35.456l36.032,29.1a8.23,8.23,0,0,1,2.161,10.21L548.271,482a8.435,8.435,0,0,1-9.991,3.351l-43.914-15.984a201.159,201.159,0,0,1-26.046,25.864l16.672,43.258a8.233,8.233,0,0,1-3.234,9.924L450,566.742a8.436,8.436,0,0,1-10.328-2.094L409.663,528.88a198.844,198.844,0,0,1-35.518,9.365l-7.187,45.778A8.232,8.232,0,0,1,359.2,591H322.529a8.434,8.434,0,0,1-7.9-6.977l-8.11-45.993a198.569,198.569,0,0,1-34.192-9.142l-30.006,35.76A8.436,8.436,0,0,1,232,566.742l-31.754-18.333a8.231,8.231,0,0,1-3.234-9.924l16.672-43.258a201.215,201.215,0,0,1-26.032-25.869L143.72,485.347A8.435,8.435,0,0,1,133.729,482L115.4,450.242a8.23,8.23,0,0,1,2.161-10.21l36.032-29.1a198.839,198.839,0,0,1-9.608-35.454l-46-8.112a8.434,8.434,0,0,1-6.977-7.9V322.8a8.232,8.232,0,0,1,6.977-7.762l45.767-7.185a198.485,198.485,0,0,1,9.4-35.5l-35.788-30.029A8.436,8.436,0,0,1,115.258,232l18.333-31.754a8.23,8.23,0,0,1,9.923-3.234l43.292,16.685a201.541,201.541,0,0,1,25.847-26.014l-16-43.959A8.435,8.435,0,0,1,200,133.729L231.758,115.4a8.23,8.23,0,0,1,10.21,2.161L271.075,153.6a198.252,198.252,0,0,1,36.781-9.85l7.186-45.772A8.232,8.232,0,0,1,322.8,91h36.667a8.434,8.434,0,0,1,7.9,6.977l8.111,46a198.7,198.7,0,0,1,35.46,9.6l29.093-36.025a8.23,8.23,0,0,1,10.21-2.161L482,133.729a8.435,8.435,0,0,1,3.351,9.991l-15.985,43.917a201.318,201.318,0,0,1,25.861,26.045l43.262-16.674a8.231,8.231,0,0,1,9.924,3.234L566.742,232a8.436,8.436,0,0,1-2.094,10.328l-35.769,30.014a198.942,198.942,0,0,1,9.366,35.517l45.778,7.187A8.232,8.232,0,0,1,591,322.8v36.667A8.434,8.434,0,0,1,584.023,367.368Z"/>
            <path id="bulb" className="cls-2" d="M449.7,435.3c-22.54,25.959-33.8,46.142-33.8,68.851s-10.367,28.139-10.367,28.139l-6.269,13.127a1.867,1.867,0,0,0-.149.151s-1.2,6.458-58.113,6.458-58.113-6.458-58.113-6.458a1.867,1.867,0,0,0-.149-0.151l-6.269-13.127S266.1,526.859,266.1,504.15s-11.257-42.892-33.8-68.851A143.9,143.9,0,1,1,449.7,435.3Z"/>
            <path id="bulb-shading-left" className="cls-3" d="M285.429,544.119c-1.6-.521-2.542-0.852-2.542-0.852l-0.034-.038-0.01-.01c-0.025-.027-0.059-0.061-0.105-0.1h0l-6.269-10.827S266.1,526.859,266.1,504.15s-11.257-42.892-33.8-68.851a144.06,144.06,0,0,1-26.98-142.329A154.72,154.72,0,0,0,239.694,426.7c24.2,27.873,36.29,49.546,36.29,73.93s11.132,30.214,11.132,30.214a42.231,42.231,0,0,0,9.283,9.223,73.835,73.835,0,0,0,8.7,5.783C293.68,545.358,288.127,544.664,285.429,544.119Z"/>
            <path id="bulb-shading-right" className="cls-3" d="M449.7,435.3c-22.54,25.959-33.8,46.142-33.8,68.851s-10.367,28.139-10.367,28.139l-6.269,11.06a1.867,1.867,0,0,0-.149.151s-0.525,1.386-17.166,2.332c2.039-1.292,2.166-2.243,2.166-2.243l6.418-11.3s9.072-20.443,9.072-41.318,10.348-39.427,31.067-63.289c20.158-23.215,34.843-56.388,34.843-91.3C465.513,261.9,409.486,201.2,333.5,197.3q3.726-.19,7.5-0.193A143.9,143.9,0,0,1,449.7,435.3Z"/>
            <path id="base" className="cls-4" d="M398.846,614.3c0-5.047-1.484-4.174-1.485-6.857,0-2.8,2.407-1.551,2.407-6.237s-2.619-2.777-2.619-6.731c0-4.351,2.089-3.723,2.089-7.058,0-3.716-2.725-3.958-2.725-7.27,0-3.419,1.983-3.67,1.983-6.618,0-3.965-1.771-3.666-1.771-6.835,0-2.479,1.983-2.987,1.983-6.051a11.532,11.532,0,0,0-1.559-5.733,17.387,17.387,0,0,1,1.909-7.2,3.176,3.176,0,0,0,1.775-3.011c0-4.172-1.72-2.133-1.72-2.133s-1.2,3.192-58.113,3.192-58.113-3.192-58.113-3.192-1.72-2.039-1.72,2.133a3.176,3.176,0,0,0,1.775,3.011,17.387,17.387,0,0,1,1.909,7.2,11.532,11.532,0,0,0-1.559,5.733c0,3.064,1.983,3.572,1.983,6.051,0,3.169-1.771,2.87-1.771,6.835,0,2.948,1.983,3.2,1.983,6.618,0,3.312-2.725,3.554-2.725,7.27,0,3.335,2.088,2.707,2.089,7.058,0,3.954-2.619,2.045-2.619,6.731s2.407,3.436,2.407,6.237c0,2.683-1.485,1.81-1.485,6.857S303.942,627.977,341,627.977,398.846,619.351,398.846,614.3Z"/>
            <path id="base-shading" className="cls-5" d="M399.058,547.711a17.387,17.387,0,0,0-1.909,7.2,11.532,11.532,0,0,1,1.559,5.733c0,3.064-1.983,3.572-1.983,6.051,0,3.169,1.771,2.87,1.771,6.835,0,2.948-1.983,3.2-1.983,6.618,0,3.312,2.725,3.554,2.725,7.27,0,3.335-2.088,2.707-2.089,7.058,0,3.954,2.619,2.045,2.619,6.731s-2.407,3.436-2.407,6.237c0,2.583,1.372,1.886,1.475,6.333,0,0.313,0,.552,0,0.615-0.237,4.937-20.023,13.171-55.231,13.562a14.15,14.15,0,0,1,3.564-4.462c4.158-3.494,6.679-4.145,6.679-9.191,0-1.352,12.516-8.425,12.515-11.107,0-.146-11.593,2.7-11.593-1.987,0-.067,11.382-7.027,11.381-10.982,0-.656-11.911.528-11.911-2.807,0-.136,11.275-8.208,11.275-11.521,0-.186-12.017.581-12.017-2.367,0-.5,12.229-7.917,12.229-11.086,0-.862-12.017,1.264-12.017-1.8,0-.431,12.441-9.984,12.441-9.984,0-3.674-12.091-2.953-12.091-2.953a2.832,2.832,0,0,0,1.657-2.032c42.411-.5,43.4-3.112,43.4-3.112s1.72-2.039,1.72,2.133A3.176,3.176,0,0,1,399.058,547.711Zm-0.216,66.714h-0.008V614.4c0-.03.012-0.061,0.012-0.091C398.846,614.347,398.842,614.383,398.842,614.425Z"/>
            <path id="question-mark" className="cls-1" d="M356,439c0,4.118-3.338,6.939-7.456,6.939H330.456c-4.118,0-7.456-2.821-7.456-6.939,0-68.854,56.017-88.033,56.017-120.983v-4.034c0-20.4-13.953-35-35-35h-4.034a35,35,0,0,0-35,35L305,321c0,4.118-3.338,6.939-7.456,6.939H279.456c-4.118,0-7.456-2.821-7.456-6.939,0-47,31.324-68.747,70-68.747S412.44,270.076,412.44,316C412.44,369.006,356,377.619,356,439Zm-24.394,25.606h14.788a10,10,0,0,1,10,10v14.788a10,10,0,0,1-10,10H331.606a10,10,0,0,1-10-10V474.606A10,10,0,0,1,331.606,464.606Z"/>
            </svg>
        </Col>
        <Col className='col-centered centeredText' >
        {stillLoading}
        </Col>
        </>
    )
}

export default ResultsLoading;
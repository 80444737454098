import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import "react-input-range/lib/css/index.css";
import {addOrRemoveObjectFromValuesArray} from '../../js/commonFunctions'
import InputRange from 'react-input-range';


const QuestionOptionCost = ({questionFormat, costSelected, setCostSelected, currentQuestion, questionValues, setQuestionValues, questionAnswers, setQuestionAnswers, defaultPriceRangeBottom, defaultPriceRangeTop, priceRangeTop}) => {

    const [minVal, setMinVal] = useState(defaultPriceRangeBottom);
    const [maxVal, setMaxVal] = useState(defaultPriceRangeTop);
    const [maxValAvail, setMaxValAvail] = useState(priceRangeTop);


    useEffect(() => {
        //Check if there is already a cost value saved, otherwise, set initial value
        if(!questionValues.find(questionValues => {return questionValues.id === (currentQuestion+'-'+0)})) {
            onRangeChange({min:minVal, max:maxVal})
        } else {
            setMinVal(questionValues.find(questionValues => {return questionValues.id === (currentQuestion+'-'+0)}).values[0].value[0])
            setMaxVal(questionValues.find(questionValues => {return questionValues.id === (currentQuestion+'-'+0)}).values[0].value[1])
        }

    })

    const onRangeChange = (rangeValuesObject) => {
        setMinVal(rangeValuesObject.min);
        setMaxVal(rangeValuesObject.max);
        let rangeValues = [rangeValuesObject.min, rangeValuesObject.max]
        addOrRemoveObjectFromValuesArray((currentQuestion+'-'+0), [{category: 'cost',value:rangeValues}], currentQuestion, questionValues, setQuestionValues, questionFormat)
    }

        let rangeSliderComponent = (questionValues.find(questionValues => {return questionValues.id === (currentQuestion+'-'+0)}))
        ?
        (<InputRange
            draggableTrack={false}
            formatLabel={value => `$${value}`}
            step={10}
            maxValue={maxValAvail}
            minValue={0}
            onChange={value => onRangeChange(value)}
            value={{min: questionValues.find(questionValues => {return questionValues.id === (currentQuestion+'-'+0)}).values[0].value[0], max:questionValues.find(questionValues => {return questionValues.id === (currentQuestion+'-'+0)}).values[0].value[1]}} 
        />)
        :
        (<InputRange
            draggableTrack={false}
            formatLabel={value => `$${value}`}
            step={10}
            maxValue={maxValAvail}
            minValue={0}
            onChange={value => onRangeChange(value)}
            value={{min: minVal, max:maxVal}} 
        />);

    return (
        <Col className='col-centered text-center'>
                <span className='costDisplay' >${minVal} - ${maxVal}</span>
            <form className="form">        
                {rangeSliderComponent}
            </form>
        </Col>
    )
}

export default QuestionOptionCost;
//Proper OS Capitalization
export const osCapitalization = (os) => {
    let properCapitalization = ''

    switch(os) {
        case 'windows':
            properCapitalization = 'Windows'
            break;
        case 'macos':
            properCapitalization = 'macOS'
            break;
        case 'chromeos':
            properCapitalization = 'Chrome OS'
            break;
        case 'ios':
            properCapitalization = 'iOS'
            break;
        case 'ipados':
            properCapitalization = 'iPadOS'
            break;
        case 'fireos':
            properCapitalization = 'Fire OS'
            break;
        case 'android':
            properCapitalization = 'Android'
            break;
        default:
            properCapitalization = capitalize(os) 
    }

    return properCapitalization;

}

export function capitalizeFirstLetter(string) {
    var words = string.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(capitalize).join(" ");
}

function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
}

export const multipleResultOptionsParsing = (property, type) => {
    let returnString = ''
    property.map((item, index) => {
        if (property.length === 1) {
            returnString += multipleResultObjects[type][item]
        }
        else {
            if((index+1) === property.length) {
                returnString += " and "+multipleResultObjects[type][item]
            } else if((index+2) === property.length) {
                returnString += multipleResultObjects[type][item]
            } else {
                returnString += multipleResultObjects[type][item]+', '
            }
        }
    })
    return returnString
}

const multipleResultObjects = {
    streamingService: {
        appleMusic: "Apple Music",
        spotify: "Spotify",
        iHeartRadio: "iHeartRadio",
        pandora: "Pandora",
        amazonMusic: "Amazon Music",
        tidal: "Tidal",
        googlePlayMusic: "Google Play Music"
    },
    assistant: {
        siri: "Siri",
        alexa: "Alexa",
        googleAssistant: "Google Assistant",
        cortana: "Cortana",
        bixby: "Bixby"
    }
}
import React from 'react';
import ReactGA from 'react-ga';

const PreviousButton =({currentQuestion, setCurrentQuestion, questionCount})=> {

    const prevQuestion =()=> {
        if (currentQuestion > 0) setCurrentQuestion(currentQuestion-1)    
        document.getElementById('quizQuestionTextRow').scrollIntoView(true)
        ReactGA.event({
            category: 'Buttons',
            action: 'Question '+(currentQuestion+1)+' to '+(currentQuestion),
            label: 'Back Button'
          });
    }

    return (
        (currentQuestion > 0)
        ?
        (
            <div className='backButton onHoverMouseChange' onClick={prevQuestion} style={(currentQuestion === (questionCount-1)) ? {marginRight: '0px'} : {}}>
                    <span className=" backButtonArrow"></span>
            </div>
        )
        :
        (
            <span></span>
        )

    )
}

export default PreviousButton;
import React, { Component } from 'react';

class Reviews extends Component {

    render() {
        return (
            <h3>Coming Soon!</h3>
        )
    }
}

export default Reviews;
import React from 'react';
import ReactGA from 'react-ga';

const NextButton =({currentQuestion, setCurrentQuestion, questionCount, questionValues})=> { 

    const nextQuestion =()=> {
        if (currentQuestion < (questionCount-1)) setCurrentQuestion(currentQuestion+1)
        document.getElementById('quizQuestionTextRow').scrollIntoView(true)
        ReactGA.event({
            category: 'Buttons',
            action: 'Question '+(currentQuestion+1)+' to '+(currentQuestion+2),
            label: 'Next Button'
          });
    }

    let nextButtonElement = (questionValues.find(valueObject => {return valueObject.questionId === currentQuestion}) !== undefined) 
        ?
        (
            <div className='nextButton onHoverMouseChange' onClick={nextQuestion} >
                    <span className=" nextButtonArrow"></span>
            </div>
        )
        :
        (
                <div className='nextButton nextButtonDisabled'>
                    <span className=" nextButtonArrow nextButtonDisabledArrow"></span>
                </div>

        )

    return (
            (currentQuestion !== questionCount-1) 
                ?
                (
                    <>
                        {nextButtonElement}
                    </>
                )
                :
                (
                    <></>
                )
    )
}

export default NextButton;
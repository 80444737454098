import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { osCapitalization, multipleResultOptionsParsing } from '../../js/resultsCommonFunctions';
import ResultSpec from './resultSpec';
import ReactGA from 'react-ga';
import axios from 'axios';


const ResultObject = ({result, quizTitle}) => {

    const resultClicked =()=> {

        //Save clicked stats to DB
        axios.post(`/devices/updateSelected/${quizTitle}`, {
            _id: result._id
        })
        .then(res => {
            // console.log(res)
        })
        .catch(function (error) {
            // console.log(error);
        });

        //Google Analytics
        ReactGA.event({
            category: 'Results',
            action: result.name+' '+result.url,
            label: 'Result Clicked'
        });
    }

    let secondaryOptions = <></>;

    if(quizTitle === 'laptops'){
        secondaryOptions = (
            <>
            <ResultSpec resultSpecTitle={'OS'} resultSpecValue={osCapitalization(result.os)} />
            <ResultSpec resultSpecTitle={'Screen Size'} resultSpecValue={result.display_size+'"'} />
            <ResultSpec resultSpecTitle={'Resolution'} resultSpecValue={result.full_display_resolution} />
            <ResultSpec resultSpecTitle={"RAM"} resultSpecValue={result.ram+' GB'} />
            <ResultSpec resultSpecTitle={'Storage'} resultSpecValue={result.storage+' GB'} />
            </>
        )
    } else if (quizTitle === 'phones') {
        secondaryOptions = (
            <>
            <ResultSpec resultSpecTitle={'OS'} resultSpecValue={osCapitalization(result.os)} />
            <ResultSpec resultSpecTitle={'Screen Size'} resultSpecValue={result.display_size+'"'} />
            <ResultSpec resultSpecTitle={'Storage'} resultSpecValue={result.storage+' GB'} />
            </>
        )
    } else if (quizTitle === 'tablets') {
        secondaryOptions = (
            <>
            <ResultSpec resultSpecTitle={'OS'} resultSpecValue={osCapitalization(result.os)} />
            <ResultSpec resultSpecTitle={'Screen Size'} resultSpecValue={result.display_size+'"'} />
            <ResultSpec resultSpecTitle={'Storage'} resultSpecValue={result.storage+' GB'} />
            </>
        )
    } else if (quizTitle === 'smartSpeakers') {
        secondaryOptions = (
            <>
            <ResultSpec resultSpecTitle={'Assistant(s)'} resultSpecValue={multipleResultOptionsParsing(result.assistant, "assistant")} />
            <ResultSpec resultSpecTitle={'Streaming Service(s)'} resultSpecValue={multipleResultOptionsParsing(result.streamingService, "streamingService")} />
            </>
        )
    }


    return (
            <Col xl='4' md='6' xs='12' className=" col-centered centeredText">
                <a href={result.url} target='_blank' rel="noopener noreferrer" onClick={()=> resultClicked()}>
                    <div className="result-object">
                        <h2 className='resultName' >{result.name}</h2>
                        <div className="result-image-container">
                            <img src={result.image_url} alt={result.name} className="result-image" />
                        </div>
                        <Row className="resultSpecSpacing" >
                            <Col >
                            <b className="resultSpecCost" >${result.cost}</b>
                            </Col>
                        </Row>
                        <ResultSpec resultSpecTitle={'Brand'} resultSpecValue={result.brand} />
                        {secondaryOptions}
                    </div>
                </a>
            </Col>
    )   

}

export default ResultObject;
import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';



const SmartSpeakerIntro = () => {

    useEffect(() => {
    })


    return (
        <>
        <h2>
            What Smart Speaker Should You Buy?
        </h2>
        <p>
            What The Tech's smart speaker finder quiz uses your quiz answers to recommend a smart speaker that matches your lifestyle and needs as closely as possible.
        </p>
        <p className='nonHeaderHeading'>
            <b>
            Here are some things to keep in mind when choosing your smart speaker preferences:
            </b>
        </p>
        <ul className='leftJustify'>
            <li>
                What The Tech prioritizes your preferred price above all else. This means that we try to find you a smart speaker that fits your budget even if it doesn't necessarily have every feature you select. 
            </li>
            <li>
                While there are many great budget smart speakers, the cost savings usually come at the expense of features such as a higher quality audio, advanced digital assistant controls, and premium build quality.
            </li>
            <li>
                If you intend to use your smart speaker's digital assistant to control smart items in your home, be sure to check that those items are compatible with your chosen assistant.
            </li>
        </ul>
        </>
        
    )
}

export default SmartSpeakerIntro;
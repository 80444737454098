import React, { Component } from 'react';

class TopTech extends Component {

    render() {
        return (
            <h3>Coming Soon!</h3>
        )
    }
}

export default TopTech;
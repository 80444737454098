import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import NoMatch from '../noMatchPage/noMatch';
import axios from 'axios';
import '../../styles/blogPost.css';
import { formatDate } from '../../js/commonFunctions';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton
  } from "react-share";
  import {
    FacebookIcon,
    TwitterIcon,
    EmailIcon
  } from 'react-share';

const BlogPost = (props) => {
    const [blogContent, setBlogContent] = useState('')
    const [currentUrl, setCurrentUrl] = useState('')
    const [noMatch, setNoMatch] = useState(false)
    const [imgToggled, setImageToggled] = useState(false)
    const [toggledImgLocation, setToggledImgLocation] = useState('')

    useEffect(() => {
        if(props.match) {
            setCurrentUrl(window.location.href)
            fetchBlog()
        }
        
    },[])

    const fetchBlog = () => {

        let queryObject = {}
        queryObject.params = {date: props.match.params.date, title: props.match.params.title}

        axios.get(`/blog/findBlog`, queryObject)
            .then(response => {
                if(response.data){
                    setBlogContent(response.data)
                }
                else {
                    setNoMatch(true)
                }
            })
            .catch(function (error){
                // console.log(error);
            })
    }

    const imgClickHandler = (e) => {
        const el = e.target.closest("img");
        if(imgToggled) {
            setImageToggled(false)
            setToggledImgLocation('')
            return;
        }
        if (el && e.currentTarget.contains(el)) {
            setToggledImgLocation(el.src)
            setImageToggled(!imgToggled)
        }
    }

    let blogContentElement = <></>


    if (blogContent) {
        blogContentElement = 
        <>
        <Helmet>
            <title>{blogContent.title} | What The Tech</title>
            <meta name="description" content={blogContent.subtitle}   />
        </Helmet>
        <Col>
        </Col>
        <Col className='blogContainer' md='10' lg='8' >
        <Row>
            <Col>
            <h1 className='centered' > {blogContent.title} </h1>
            <p className='centered' > {blogContent.subtitle} </p>
            <p className='centered smallText'>
            <span><b>Author: </b></span>
            <span> {blogContent.author} </span>
            <span>&nbsp; | &nbsp;</span>
            <span><b>Date: </b></span>
            <span> {blogContent.date_published} </span>
            </p>
            <div className='mainImageContainer' >
            <img src={require(`../../../public/images/blog/${props.match.params.title}/${blogContent.main_image.name}`)} className='mainImage mx-auto' alt={blogContent.main_image.title} />
            </div>
            <p className='imageSource smallText' >Image: <a href={`https://www.${blogContent.main_image.source_url}`} target='_blank' >{blogContent.main_image.source_url}</a> </p>
            <div onClick={imgClickHandler} className="containerDiv" dangerouslySetInnerHTML={{__html: blogContent.content_html}} />
            {/* Lightbox for images */}
            <a href="#_" className="lightbox" id="img1" onClick={imgClickHandler} style={{ display: (imgToggled ? 'block' : 'none') }}>
            <img src={toggledImgLocation} />
            </a>
            </Col>
        </Row>
        <Row>
        <Col className='centeredText shareButtonSection' >
            <h3 className="shareTitle" >Share</h3>
            <EmailShareButton url={currentUrl} subject={blogContent.title} body={blogContent.subtitle} separator={"\n \n"} >
                <EmailIcon size={48} round={true} bgStyle={{fill: "#1C7FA6"}} />
            </EmailShareButton>
            <TwitterShareButton url={currentUrl} title={blogContent.title} >
                <TwitterIcon size={48} round={true} />
            </TwitterShareButton>
            <FacebookShareButton url={currentUrl} quote={blogContent.title} >
                <FacebookIcon size={48} round={true} />
            </FacebookShareButton>
        </Col>
        </Row> 
        </Col>
        <Col>
        </Col>
        </>
    } else if (noMatch) {
        blogContentElement = <NoMatch />
    }


    return (
        <>
        {blogContentElement}
        </>
    )
}

export default BlogPost;
import React from 'react';
import { Row, Col } from 'reactstrap';


const ResultSpec = ({resultSpecTitle, resultSpecValue}) => {

    return (
        <Row className="resultSpecSpacing" >
            <Col className='resultSpecTitle'>
                <span >{resultSpecTitle}</span>
            </Col>
            <span ><b className='resultSpecColon'>:</b></span>
            <Col className='resultSpecValue' >
                <span >{resultSpecValue}</span>
            </Col>
        </Row>
    )
}

export default ResultSpec;
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';

ReactDOM.render(
    <HttpsRedirect>
    <Router>
        <App />
    </Router>
    </HttpsRedirect>, 
    document.getElementById('root')
);
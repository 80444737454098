import React, {useState, useEffect} from 'react';
import ResultReason from './resultReason';
import { resultObjectToArray } from '../../js/resultsParsing';
import { Row, Col } from 'reactstrap';

const ResultReasons = ({matchedQuery, quizTitle}) => {
    const [reasonsArray, setReasonsArray] = useState(resultObjectToArray(matchedQuery, quizTitle))


    let noResultsReason = <></>

    if (quizTitle === 'smartSpeakers') {
        noResultsReason =
        <Col md="12" lg="12"  >
            <div className="centeredText noMatches">
                <b><span>Unable To Find Results In Your Price Range With Your Preferred Digital Assistant</span></b>
            </div>
            <br />
        </Col>
    } else {
        noResultsReason =
        <Col md="12" lg="12"  >
            <div className="centeredText noMatches">
                <b><span>Unable To Find Results In Your Price Range</span></b>
            </div>
            <br />
            <div className="centeredText">
                <span>These recommended {quizTitle} match your operating system preferences.</span>
            </div>
        </Col>
    }

    let reasons = (reasonsArray.length > 1) ?    
    reasonsArray.map((reason, index) => (
        <Col md="12" lg="6" key={"key"+index} >
            <div className="centeredText">
                <ResultReason reason={reason} quizTitle={quizTitle}/>   
            </div>
        </Col>
    ))
    :
    (
        noResultsReason
    )


    return (
        <>
        <Row>
            <Col>
            <h2 className="resultsSubHeader">Matched Preferences</h2>
            </Col>
        </Row>
        <Row>
            <Col>
            </Col>
            <Col xl="6" lg="8" md="9" sm="12" className="matchedPreferencesContainer">
                <Row >
                    {reasons}
                </Row>
            </Col>
            <Col>
            </Col>
        </Row>
        </>
    )
}

export default ResultReasons;
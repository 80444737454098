import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const SideNav = ({questions, currentQuestion, setCurrentQuestion, questionValues}) => {
    let questionButtons = '';

    const changeCurrentQuestion = (index) => {
        setCurrentQuestion(index)
        ReactGA.event({
            category: 'Buttons',
            action: 'Moved from question '+(currentQuestion+1)+' to question '+(index+1),
            label: 'SideNav Button'
          });
    }


    if(questions){
        questionButtons = questions.map((question, index, key) =>
        
        ((questionValues.find(valueObject => {return valueObject.questionId === (question.question_number-2)}) !== undefined) || (question.question_number === 1) || (questionValues.find(valueObject => {return valueObject.questionId === (question.question_number-1)})))
        ?
        (
            <span 
                onClick={() => changeCurrentQuestion(index)} 
                key={question.question_number} 
                className={"sidebarButton sidebarButtonActive "}

            >
                <span className={(questionValues.find(valueObject => {return valueObject.questionId === (question.question_number-1)}) ? 'sidebarButtonAnsweredCircle' : 'sidebarButtonNotAnsweredCircle')}></span>
                <p className='sidebarButtonText'>{question.question_sidebar_text}</p>
            </span>
        )
        :
        (
            <span 
                key={question.question_number} 
                className="sidebarButton sidebarButtonInactive"
            >
                <span className='sidebarButtonInactiveCircle' ></span>
                <p className='sidebarButtonText sidebarButtonInactive'>{question.question_sidebar_text}</p>
            </span>
        )

        )
    } else {
        questionButtons = 'No Questions'
    }

    return (
        <div>
            {questionButtons}
        </div>
    )
}

export default SideNav;
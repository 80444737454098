import React, { Component } from 'react';
import { Col } from 'reactstrap';
import LogoSVG from './logo.svg'
import '../../styles/logoPage.css'

class Logo extends Component {

    render() {
        return (
            <Col className='centered '>
                <img className='logo' src={LogoSVG} />
            </Col>
        )
    }
}

export default Logo;
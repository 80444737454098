import React, {useState, useEffect} from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import {createResultsQuery} from '../../js/resultsQueryBuilder';

const SubmitButton =({questionValues, quizTitle, questionCount, setParamsObject, setLoadingResults})=> {

    const submitButtonClicked = () => {
        
        setParamsObject({ params: createResultsQuery(createFinalValuesArray(questionValues))})
        setLoadingResults(true);

        ReactGA.event({
            category: 'Buttons',
            action: 'Completed '+quizTitle+' quiz',
            label: 'Submit Button'
        });
    }

    const createFinalValuesArray = (valuesObjects) => {
        let finalValues = [];
        valuesObjects.map((object) =>
            object.values.map((valuesArray) =>
                finalValues.push(valuesArray)
            )
        )
        return finalValues;
    }

    const checkCompletedQuestions = (questionValues, questionCount) => {
        let completedQuestionCount = [];
        questionValues.forEach(value => {
            for(let id = 0; id < questionCount; id++) {
                if(value.questionId === id && !completedQuestionCount.includes(id)) {
                    completedQuestionCount.push(id);
                    break;
                }
            }
        });
        if(completedQuestionCount.length === questionCount) {
            return true
        } else {
            return false
        }

    }

    let submitButton = (checkCompletedQuestions(questionValues, questionCount)) 
        ?
        (
            <Button className='submitButton submitButtonEnabled' onClick={()=> submitButtonClicked()}>
                Results
            </Button>
        )
        :
        (
            <Button 
                disabled
                className='submitButton'
            >
                Results
            </Button>
        )


    return (
        <>
            {submitButton}
        </>
    )
}

export default SubmitButton;
const quizList = [
    {
        quizTitle: 'laptops',
        quizImage: 'laptop'
    },
    {
        quizTitle: 'phones',
        quizImage: 'phone'
    },
    {
        quizTitle: 'tablets',
        quizImage: 'tablet'
    },
    {
        quizTitle: 'smartSpeakers',
        altQuizTitle: 'smart speakers',
        quizImage: 'smart_speaker'
    }
]
export {quizList};
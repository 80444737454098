import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import {addOrRemoveObjectFromValuesArray} from '../../js/commonFunctions'

const QuestionOption = ({questionFormat, currentQuestion, optionDetails, radioSelected, setRadioSelected, checkboxSelected, setCheckboxSelected, questionValues, setQuestionValues, questionAnswers, setQuestionAnswers, optionIndex}) => {
    useEffect(() => {
        if(questionAnswers[currentQuestion] || questionAnswers[currentQuestion] === 0) {
            if(questionFormat === "multipleChoiceOne") {
                setRadioSelected(questionAnswers[currentQuestion])
            } else {
                setCheckboxSelected(questionAnswers[currentQuestion])
            }
        } else {
            setRadioSelected('');
            setCheckboxSelected([]);
        }
    }, [currentQuestion, questionAnswers, questionFormat, setCheckboxSelected, setRadioSelected])

    const onCheckboxBtnClick = (selected) => {
        addOrRemoveObjectFromValuesArray((currentQuestion+'-'+selected), optionDetails.option_value, currentQuestion, questionValues, setQuestionValues, questionFormat)
        const checkboxIndex = checkboxSelected.indexOf(selected);
        if (checkboxIndex < 0) {
          checkboxSelected.push(selected);
        } else {
          checkboxSelected.splice(checkboxIndex, 1);
        }
        setCheckboxSelected([...checkboxSelected]);
        let tempQuestionAnswers = questionAnswers;
        tempQuestionAnswers[currentQuestion] = [...checkboxSelected];
        setQuestionAnswers(tempQuestionAnswers);
    }

    const onRadioBtnClick = (selected) => {
        addOrRemoveObjectFromValuesArray((currentQuestion+'-'+selected), optionDetails.option_value, currentQuestion, questionValues, setQuestionValues, questionFormat)
        setRadioSelected(selected)
        let tempQuestionAnswers = questionAnswers;
        tempQuestionAnswers[currentQuestion] = selected;
        setQuestionAnswers(tempQuestionAnswers);
    }

    let questionOptionElement = '';

    if(questionFormat === "multipleChoiceOne"){
        questionOptionElement = 
            <div className="questionOptionBlock">
                <span
                    onClick={() => onRadioBtnClick(optionIndex)}
                    className='onHoverMouseChange'
                >
                    <div className='questionOptionSquare'>
                        <div className='questionOptionCircle' style={(currentQuestion % 2 === 0) ? {backgroundColor: '#17B9BD'} : {backgroundColor: '#1762BD'}}>
                            <img src={require(`../../images/quizzes/${optionDetails.option_image}.svg`)} className='questionOptionImage' alt={optionDetails.option_image} />
                        </div>                       
                    </div>
                    <span
                        className={'radioButton ' + (radioSelected === optionIndex ? 'radioButtonSelected' : 'radioButtonNotSelected')}
                    >
                    </span>
                    <span className ="questionOptionText" >{optionDetails.option_text}</span>
                    <span className="questionOptionSubtext" >{optionDetails.option_subtext}</span>
                </span>
            </div>
    } else {
        questionOptionElement = 
            <div className="questionOptionBlock">
                <span
                    onClick={() => onCheckboxBtnClick(optionIndex)}
                    className='onHoverMouseChange'
                >
                    <div className='questionOptionSquare'>
                        <div className='questionOptionCircle' style={(currentQuestion % 2 === 0) ? {backgroundColor: '#17B9BD'} : {backgroundColor: '#1762BD'}}>
                            <img src={require(`../../images/quizzes/${optionDetails.option_image}.svg`)} className='questionOptionImage' alt={optionDetails.option_image} 
                            />
                        </div>                       
                    </div>
                    <span className={'checkboxButton ' + (checkboxSelected.includes(optionIndex) ? 'checkboxActive' : 'checkboxInactive')} >
                        <span className={(checkboxSelected.includes(optionIndex) ? 'checkboxActiveCheckmark' : 'checkboxInactiveCheckmark')} ></span>
                    </span>
                    <span className ="questionOptionText" >{optionDetails.option_text}</span>
                    <span className="questionOptionSubtext" >{optionDetails.option_subtext}</span> 
                </span>          
            </div>
    }



    return (
        <Col xs="6" md="4" lg='3'  className='col-centered' >
            {questionOptionElement}
        </Col>
    )
}

export default QuestionOption;
import React, {useState, useEffect} from 'react';
import ResultObject from './resultObject';
import { Row, Col, Button } from 'reactstrap';
import { removeLastLetter, capitalizeFirstLetter, splitCamelCaseString } from '../../js/commonFunctions';
import '../../styles/quiz/results.css'
import ResultReasons from './resultReasons'
import NoMatch from '../noMatchPage/noMatch'
import ResultDisclaimerDropdown from './resultDisclaimerDropdown';
import ResultPriceDisclaimer from './resultPriceDisclaimer';
import axios from 'axios';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton
  } from "react-share";
  import {
    FacebookIcon,
    TwitterIcon,
    EmailIcon
  } from 'react-share';

const ResultsPage = (props) => {

    const [quizTitle, setQuizTitle] = useState('')
    const [returnedResults, setReturnedResults] = useState([])
    const [returnedSimilarResults, setReturnedSimilarResults] = useState([])
    const [invalidId, setInvalidId] = useState(false)
    const [matchedQuery, setMatchedQuery] = useState('')
    const [currentUrl, setCurrentUrl] = useState('')
    const [resultDate, setResultDate] = useState('')

    useEffect(() => {
        if(props) {
            setQuizTitle(props.match.params.quiz_title)
            setCurrentUrl(window.location.href)
 
            //Use ID sent in through the URL to retrieve result document, including an array of IDs that correlate to recommended devices
            axios.get(`/devices/fetchResults/${props.match.params.quiz_title}`, {params: props.match.params.id})
            .then(response => {
                if(!response.data){
                    setInvalidId(true)
                    return;
                }
                setMatchedQuery(response.data.matchedQuery)

                if(response.data.deviceObjects.length > 0) {
                    setReturnedResults(response.data.deviceObjects)
                } else {
                    setReturnedResults(response.data.devices)
                }
                if(response.data.similarDeviceObjects.length > 0) {
                    setReturnedSimilarResults(response.data.similarDeviceObjects)
                } else {
                    setReturnedSimilarResults(response.data.similarDevices)
                }
                if(response.data.date) {
                    setResultDate(response.data.date)
                }
            })
            .catch((error) => {
                setInvalidId(true)
            });
        }
    },[])

    const retakeQuizClicked = () => {
        ReactGA.event({
            category: 'Buttons',
            action: 'Retake Quiz button pushed.'
        });
    }

    let messageForPhones = (quizTitle === "phones")?
        (
            <h3 className='phonesMessage'>(Select your mobile carrier on Amazon before purchasing a phone)</h3>
        )
        :
        (
            <></>
        )

    let resultsObjects = (returnedResults.length > 0) 
        ?
        (
            returnedResults.map((result, index) =>
                <ResultObject 
                    key={index}
                    className='onHoverMouseChange'
                    result={result}
                    quizTitle={quizTitle}
                />
            )
        )
        :
        (<></>)


    let similarResultsObjects = (returnedSimilarResults.length > 0) 
        ?
        (
            returnedSimilarResults.map((result, index) =>
                <ResultObject 
                    key={index}
                    className='onHoverMouseChange'
                    result={result}
                    quizTitle={quizTitle}
                />
            )
        )
        :
        (<></>)

    let similarResultsBlock = (returnedSimilarResults.length > 0) 
    ?
    (
        <>
        <Row className='width100 rowNoMargin'>
            <Col className='centeredText'>
                <h2 className='resultsSubHeader' >Similar {capitalizeFirstLetter(quizTitle)}</h2>
                <ResultDisclaimerDropdown />
                <ResultPriceDisclaimer resultDate={resultDate} />
            </Col>
        </Row>
        <Row className='rowNoMargin width100'>
            {similarResultsObjects}
        </Row>
        </>
    )
    :
    (<></>)




    if(invalidId) {
        return (
            <NoMatch />
        )
    }
    else if(returnedResults.length > 0) {
        return(
            <>
            <Helmet>
                <title>Recommended {capitalizeFirstLetter(quizTitle)} | What The Tech</title>
            </Helmet>
            <Row className='width100 rowNoMargin'>
                <Col className='centeredText'>
                    <ResultReasons matchedQuery={matchedQuery} quizTitle={quizTitle}/>
                    <h2 className='resultsSubHeader' >Recommended {capitalizeFirstLetter(quizTitle)}</h2>
                    <p className="selectDeviceHeader" >(Select a {splitCamelCaseString(removeLastLetter(quizTitle))} to view on Amazon)</p>
                    <ResultDisclaimerDropdown />
                    <ResultPriceDisclaimer resultDate={resultDate} />
                    {/* {messageForPhones} */}
                </Col>
            </Row>
            <Row className='rowNoMargin width100'>
                {resultsObjects}
            </Row>
            {/* {similarResultsBlock} */}
            <Row className='rowNoMargin width100'>
                <Col className='centered' >
                    <Link to={{
                        pathname: `/quizzes/${quizTitle}`,
                        state: {fromResults: true}
                        }} >
                        <Button size='lg' className='retakeQuizButton' onClick={retakeQuizClicked} >
                            Retake Quiz
                        </Button>
                    </Link>
                </Col>
            </Row>
            {/* <Row className='width100 rowNoMargin'>
                <Col className='centeredText shareButtonSection' >
                    <h3 className='resultsSubHeader' >Share Results</h3>
                    <EmailShareButton url={currentUrl} subject={"My What The Tech "+capitalizeFirstLetter(quizTitle)+" Quiz Results"} body={"What The Tech recommended these "+splitCamelCaseString(quizTitle)+" after I took their 'What "+capitalizeFirstLetter(removeLastLetter(quizTitle))+" Should I Buy' quiz."} separator={"\n \n"} >
                        <EmailIcon size={48} round={true} bgStyle={{fill: "#1C7FA6"}} />
                    </EmailShareButton>
                    <TwitterShareButton url={currentUrl} title={"What The Tech recommended these "+splitCamelCaseString(quizTitle)+" after I took their 'What "+capitalizeFirstLetter(removeLastLetter(quizTitle))+" Should I Buy' quiz."} >
                        <TwitterIcon size={48} round={true} />
                    </TwitterShareButton>
                    <FacebookShareButton url={currentUrl} quote={"What The Tech recommended these "+splitCamelCaseString(quizTitle)+" after I took their 'What "+capitalizeFirstLetter(removeLastLetter(quizTitle))+" Should I Buy' quiz."} >
                        <FacebookIcon size={48} round={true} />
                    </FacebookShareButton>

                </Col>
            </Row> */}
            </>
        )
    } else {
        return (
            <Row className='width100 rowNoMargin'>
                <Col className='centeredText'>
                    <h1 className='resultsHeader' ></h1>
                </Col>
            </Row>
        )
    }
}

export default ResultsPage;
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import YoutubeVideoObject from './youtubeVideoObject'
import axios from 'axios';
import '../../styles/videosPage.css';


const VideosPage = () => {

    const [videosList, setVideosList] = useState()

    useEffect(() => {
        window.scrollTo(0,0);

        axios.get("https://www.googleapis.com/youtube/v3/search?key=AIzaSyD1L7sBeNAwF_H1-roWW-D5nQy4phHtqNc&channelId=UCbu6BfRtdjxDVqj0b9BvTHg&part=snippet,id&order=date&maxResults=50")
        .then((response) => {
            if(response.data.items) {
                setVideosList(response.data.items)
            } else {
                // setVideosList([])
            }
        })
        .catch((error) => {
            // console.log(error)
        })

    },[])

    let videos = (Array.isArray(videosList) && videosList.length > 0) ?
    
        videosList.map((videoData, index) => {
                    if (index === (videosList.length-1)) {
                        return <></>;
                    }
                    return <YoutubeVideoObject key={index} videoData={videoData}  />
                }
            )
        :
        <></>


        return (
            <Col>
            <Helmet>
                <title>What The Tech | Videos</title>
                <meta name="description" content={"What The Tech videos will help you make an informed decision on your next tech purchase. Watch short videos to get personalized technology recommendations and advice."}   />
            </Helmet>
            <Row>
                <Col className="centeredText pageTitle">
                <h1>Videos</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
                <Col className='centered' sm="11" md="11" lg="11">
                    <Row>
                    {videos}

                    </Row>
                </Col>
                <Col>
                </Col>
            </Row>
            </Col>
        )
}

export default VideosPage;
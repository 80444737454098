import React, { useState, useEffect } from 'react';
import QuizIntro from './quizIntro'
import QuizPage from './quizPage'
import { Helmet } from 'react-helmet'
import { capitalizeFirstLetter, removeLastLetter, splitCamelCaseString } from '../../js/commonFunctions';
import NoMatch from '../noMatchPage/noMatch';
const quizzes = {
    laptops: 'laptops',
    phones: 'phones',
    tablets: 'tablets',
    smartSpeakers: 'smartSpeakers'
}



const QuizContainer = (props) => {

    const [quizStarted, setQuizStarted] = useState(false)
    const [quiz_title, setQuiz_title] = useState('')
    let renderedContent = <></>

    useEffect(() => {
        if(props.match.params.quiz_title){
            setQuiz_title(props.match.params.quiz_title)
        }

        if(props.location.state) {
            if(props.location.state.fromResults) {
                setQuizStarted(true)
            }
        }
    })

    if (quizzes[quiz_title]) {
        if (quizStarted) {
            renderedContent = <QuizPage quiz_title={quiz_title} />
        } else {
            renderedContent = <QuizIntro quiz_title={quiz_title} setQuizStarted={setQuizStarted} />
        }
    } else {
        renderedContent = <NoMatch />
    }

    return (
        <>
            <Helmet>
                <title>{capitalizeFirstLetter(removeLastLetter(quiz_title))} Finder Quiz | What The Tech</title>
                <meta name="description" content={"What "+splitCamelCaseString(removeLastLetter(quiz_title))+ " should you buy in 2021? Take this short "+splitCamelCaseString(removeLastLetter(quiz_title))+ " finder quiz and get personalized recommendations of "+splitCamelCaseString(quiz_title)+" we recommend for your lifestyle and budget."}   />
            </Helmet>
            {renderedContent}
        </>
    )


}

export default QuizContainer;
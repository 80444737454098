import React from 'react';
import { Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

const PageTitle = () => {

    // const scrollToQuizzes = () => {
    //     document.getElementById('allQuizzes').scrollIntoView()
    //     ReactGA.event({
    //         category: 'Buttons',
    //         action: 'Scroll To Quizzes button pushed.'
    //       });
    // }

    return (
        <>
        <Col>
        </Col>
        <Col xs='12' md='4' className=' my-auto' >
            <h1 className='headingStyles'>What the tech do you need?</h1>
            <p className='generalText headingText'>Take short quizzes to get personalized technology recommendations.</p>
            <p className='generalText headingText'>Select a quiz to find out what tech best fits your lifestyle and budget.</p>
            <Link to={`quizzes`} >
            <Button className='buttonStyling' size='lg' >
                All Quizzes
            </Button>
            </Link>
        </Col>
        <Col sm='12' md='6' className='centeredText'>
            <div className="pageTitleImageContainer">
                <img src={require('../../images/homePage/devices.svg')} className='pageTitleImage ' alt='What tech should you buy?'/>
            </div>
        </Col>
        <Col>
        </Col>
        </>

    )
}

export default PageTitle;
import React from 'react';
import { UncontrolledCollapse, CardBody, Card } from 'reactstrap';

const ResultDisclaimerDropdown = () => {

    return (
    <>
        <a href="" target='_blank' rel="noopener noreferrer" id="toggler">
            <p className="disclaimer">Amazon Associate Disclaimer&ensp; <i className="arrow down"></i></p>
        </a>
        <UncontrolledCollapse toggler="#toggler">
            <Card>
                <CardBody>
                    <p>What The Tech is an Amazon Associate and earns revenue based on qualifying Amazon affiliate purchases.</p>
                    <p>
                    Recommended products are not sponsored by Amazon, recommendations are selected purely based on answers to What The Tech quizzes.
                    </p>
                </CardBody>
            </Card>
        </UncontrolledCollapse>
    </>
    )
}

export default ResultDisclaimerDropdown;
import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';



const TabletIntro = () => {

    useEffect(() => {
    })


    return (
        <>
        <h2>
            What Tablet Should You Buy?
        </h2>
        <p>
            What The Tech's tablet finder quiz uses your quiz answers to recommend a tablet that matches your lifestyle and needs as closely as possible.
        </p>
        <p>
            <b className='nonHeaderHeading'>
                Here are some things to keep in mind when choosing your tablet preferences:
            </b>
        </p>
        <ul className='leftJustify'>
            <li>
                What The Tech prioritizes your preferred price above all else. This means that we try to find you a tablet that fits your budget even if it doesn't necessarily have every feature you select. 
            </li>
            <li>
                There are many tablet operating systems to choose from. This makes it important to know which operating systems support the apps you would like to install.
            </li>
            <li>
                Cost savings usually come at the expense of features such as a higher-resolution screen, advanced camera capabilities, and greater storage.
            </li>
            <li>
                All recommended tablets have access to cloud storage to offload photos and videos to free up space on your device for new content. This means that you can access your media even on tablets with little storage space.
            </li>
            <li>
                Not every tablet works with a stylus. If you intend to use a stylus, make sure it is compatible with your tablet.
            </li>
        </ul>
        </>
    )
}

export default TabletIntro;
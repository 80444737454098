import React, { Component } from 'react';
import { Col } from 'reactstrap';

class NoMatch extends Component {

    render() {
        return (
            <Col className='centered noMatch'>
                <h1>404 - Page Not Found</h1>
            </Col>
        )
    }
}

export default NoMatch;
import React from 'react';
import QuizBlock from './quizBlock';
import '../../styles/homePage.css';
import {quizList} from '../../js/quizList';

const QuizGrid = (props) => {
    
    const quizBlockList = quizList.map((quiz, index) => (
        <QuizBlock key={index} quizTitle={quiz.quizTitle} quizImage={quiz.quizImage} altQuizTitle={quiz.altQuizTitle} />
    ));

    return (
        <>
            {quizBlockList}
        </>
    )

}

export default QuizGrid;